import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "layout": "post",
  "title": "Right Fit Mentoring",
  "author": "Ashley",
  "date": "2020-01-29T08:04:36.000Z",
  "categories": ["mentorship", "Personal-Professional Growth"],
  "slug": "right-fit-mentoring",
  "draft": false,
  "meta_title": "Right Fit Mentoring",
  "cover": "../../images/wp_blog_images/two-woman-chatting-1311518-1.jpg"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <blockquote>
      <p parentName="blockquote">{`“The delicate balance of mentoring someone is not creating them in your own image, but giving them the opportunity to create themselves.” -Steven Spielberg`}</p>
    </blockquote>
    <p>{`When I first started dabbling in finding a mentor, I tried finding people who I aspired to be like or were in careers in which I was interested in.  At first, everything seemed great but often I would pause and find myself saying “but that’s not me” or “I don’t want the same things”. I gave up on mentorship for a while and determined that mentoring just wasn’t for me, nor worth my time.  `}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1280px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.5625%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAQCAwX/xAAUAQEAAAAAAAAAAAAAAAAAAAAC/9oADAMBAAIQAxAAAAEcnUHjD4x//8QAGhAAAwEAAwAAAAAAAAAAAAAAAQIDACEiMf/aAAgBAQABBQKaFcW4srPQani9x//EABYRAQEBAAAAAAAAAAAAAAAAAAEAIv/aAAgBAwEBPwFBs3//xAAXEQADAQAAAAAAAAAAAAAAAAAAAREC/9oACAECAQE/AU4TTP/EABoQAAICAwAAAAAAAAAAAAAAAAABEBEhMXH/2gAIAQEABj8CW+FlpYmz/8QAGRAAAwEBAQAAAAAAAAAAAAAAAAEhETFh/9oACAEBAAE/IexhDJ2g3PMPnC18ei0arkP/2gAMAwEAAgADAAAAEEAP/8QAGBEBAQADAAAAAAAAAAAAAAAAAQARITH/2gAIAQMBAT8QR5KHG7//xAAWEQEBAQAAAAAAAAAAAAAAAAABABH/2gAIAQIBAT8QWANv/8QAGhABAQADAQEAAAAAAAAAAAAAAREAIXFRYf/aAAgBAQABPxA5WoL0bbrkzyKQJteYs9ADPD7kQgOGOht2rgM8YC0Gf//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "two woman chatting 1311518 1",
          "title": "two woman chatting 1311518 1",
          "src": "/static/90b30620590cd8496983e3868cd0b2cf/eea4a/two-woman-chatting-1311518-1.jpg",
          "srcSet": ["/static/90b30620590cd8496983e3868cd0b2cf/cb69c/two-woman-chatting-1311518-1.jpg 320w", "/static/90b30620590cd8496983e3868cd0b2cf/c08c5/two-woman-chatting-1311518-1.jpg 640w", "/static/90b30620590cd8496983e3868cd0b2cf/eea4a/two-woman-chatting-1311518-1.jpg 1280w", "/static/90b30620590cd8496983e3868cd0b2cf/0f98f/two-woman-chatting-1311518-1.jpg 1920w", "/static/90b30620590cd8496983e3868cd0b2cf/58895/two-woman-chatting-1311518-1.jpg 2448w"],
          "sizes": "(max-width: 1280px) 100vw, 1280px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{` Photo by mentatdgt from Pexels`}</p>
    <p>{`As we neared a new PCS`}{`*`}{` and I was looking at a career shift, I decided to try something different. I wanted to find someone who knew nothing about me but also had no problem pushing me to do something different.  I am innately stubborn and often would make up excuses about why I didn’t want to push past my boundaries. I needed someone who wouldn’t settle for my excuses and would ask me why I was resisting change.  `}</p>
    <p>{`These mentors have been the most impactful and are the ones who often come by happenstance rather than chosen out of a group.  It usually started with someone asking me “why” when I wasn’t pursuing something and developing from there. Not everyone is comfortable with the persistent “why” but that is what I needed in my growth.  My mentors taught me not to settle for what was easy but push to “why” I was holding back.  `}</p>
    <p>{`Everyone hears that they need a good mentor and that the guidance is invaluable.  I wholeheartedly agree but believe that you need to find the right fit for you. If it doesn’t feel right, it’s okay to say farewell to that mentor and find someone who can help you grow. Sometimes the best mentors aren’t planned but rather found in the day to day routine of life. `}</p>
    <p>{`*`}{`PCS is a permanent change of station for service members and their families.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      